import LandingContainer from '../features/Auth/components/LandingContainer'
import { NextPage } from 'next'
import LoginForm from '../features/Auth/components/LoginForm'
import PageHead from '../components/Admin/AdminHeader/PageHead'
import { SupportChat } from '../components/Admin/utils/SupportChat'
import { useEffect, useLayoutEffect } from 'react'
import { ChatWidget } from '@clickconnector/widget-sdk'

const Login: NextPage = () => {
    useEffect(() => {
        ChatWidget.waitForWidgetReady().then(() => {
            SupportChat.showBubble()
        })
    }, [])

    return (
        <LandingContainer>
            <PageHead title="Login" />
            <LoginForm />
        </LandingContainer>
    )
}

export default Login
